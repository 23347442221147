import React, { useMemo } from 'react';
import { scalePow } from 'd3-scale';
import { Layer } from 'react-map-gl';
import { hoverExpr } from './constants';
import { SourcesLayers } from '../SourcesLayers';
import { useMapContext } from '../MapContext';

export const S22_sundmused: React.FC = () => {
	const {
		mapId,
		mapData: { sources },
	} = useMapContext();

	const expr = useMemo(() => {
		let expr = [];

		const colorScale = scalePow<string>().domain([0, 150]).exponent(0.28).range(['#DFECF6', '#0072CE']).clamp(true);

		for (let i = 0; i <= 150; i += 10) {
			expr.push(i, colorScale(i));
		}

		return expr;
	}, []);

	const layers = {
		'S22-sundmused': (
			<Layer
				key={'S22-sundmused'}
				id={'S22-sundmused'}
				type={'fill'}
				source={'S22-sundmused'}
				paint={{
					'fill-color': ['case', hoverExpr, '#F79DA3', ['interpolate', ['linear'], ['get', 'count'], ...expr]],
					'fill-outline-color': ['case', hoverExpr, '#EF3340', 'rgba(0,0,0,0)'],
					'fill-opacity': 0.7,
				}}
			/>
		),
	};

	if (!sources) return null;

	return <SourcesLayers mapId={mapId} sources={sources} layers={layers} />;
};
