import * as React from 'react';
import styled from 'styled-components';
import InfoIcon from '../../images/info_ikoon_medium.svg';
import { LocalizedLink } from '../LocalizedLink';
import { useLocation } from '@reach/router';

interface MoreInfo {
	id: string;
}

const Wrapper = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.02em;
`;

const StyledLink = styled(LocalizedLink)`
	text-decoration: none;

	:hover svg * {
		fill: #fff;
	}

	svg {
		height: 1em;
	}
`;

export const MoreInfo: React.FC<MoreInfo> = ({ id, children }) => {
	const location = useLocation();

	return (
		<Wrapper>
			<StyledLink to={`${location.pathname}/${id}`} state={{ parent: true }}>
				<InfoIcon />
			</StyledLink>
			{children}
		</Wrapper>
	);
};
