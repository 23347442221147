import * as React from 'react';
import styled from 'styled-components';

/* export const Wrapper = styled.div`
	object-fit: cover;
	width: 176px;
`; */

export const ThumbNailImg: React.FC = ({ children }) => {
	return <>{children}</>;
};
