import { useEffect, useRef } from 'react';

export function usePrevious<D>(value: D) {
	const ref = useRef<D>();

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
}
