import React from 'react';
import styled from 'styled-components';
import LineIcon from '../../images/line_fat.svg';
import { BaseControl, BaseControlProps } from 'react-map-gl';
import { screenSize } from '../../styles/ScreenSize';

const Wrapper = styled.div`
	position: fixed;

	background-color: white;
	box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
	border-radius: 2px;

	display: flex;
	flex-direction: row;
	align-items: center;

	padding: 3px 7px;
	max-width: 320px;

	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.02em;

	color: #0072ce;
	transition: transform 60ms;

	b {
		display: inline-block;
		width: 100%;

		:not(:first-of-type) {
			margin-top: 5px;
		}
	}

	${screenSize.sm} {
		max-width: 256px;
	}
`;

const PopupIcon = styled(LineIcon)<{ color?: string }>`
	width: 18px;
	height: 18px;
	margin: 4px 6px 4px 0;

	path {
		fill: ${(p) => p.color};
	}
`;

export interface PopupProps extends BaseControlProps {
	position: [number, number];
	icon?: string;
}

export class Popup extends BaseControl<PopupProps, HTMLDivElement> {
	_render() {
		const { position, icon } = this.props;
		const [x, y] = position;
		return (
			<Wrapper ref={this._containerRef} style={{ transform: `translate(calc(${x}px - 50%), calc(${y + 15}px)` }}>
				{!!icon && <PopupIcon color={icon} />}
				{this.props.children}
			</Wrapper>
		);
	}
}
