import React, { useMemo } from 'react';
import { Expression } from 'mapbox-gl';
import { scaleLinear } from 'd3-scale';
import { Layer } from 'react-map-gl';
import { useMapContext } from '../MapContext';
import { gridSourceLayerName, hoverExpr } from './constants';

export const S09_rohealad: React.FC = () => {
	const {
		mapData: { data },
		activeLayers,
		mapId,
	} = useMapContext();

	const expr = useMemo(() => {
		let expr: Expression = ['match', ['get', 'GRD_INSPIR']];

		if (!data) return 'rgba(0,0,0,0)';

		if (activeLayers.includes('pop')) {
			const maxPop = Math.max(...Object.entries(data).map(([key, value]) => value.pop));

			const colorScaleCat0 = scaleLinear<string>().domain([0, maxPop]).range(['#CCCCCC', '#333333']).clamp(true);
			const colorScaleCat1 = scaleLinear<string>()
				.domain([0, 1, maxPop])
				.range(['#99D5B1', '#7DA98F', '#202B23'])
				.clamp(true);
			const colorScaleCat2 = scaleLinear<string>()
				.domain([0, 1, maxPop])
				.range(['#33AB61', '#338750', '#0C2214'])
				.clamp(true);

			for (let [key, value] of Object.entries(data)) {
				switch (value['cat']) {
					case 0:
						expr.push(key, colorScaleCat0(value['pop']));
						break;
					case 1:
						expr.push(key, colorScaleCat1(value['pop']));
						break;
					case 2:
						expr.push(key, colorScaleCat2(value['pop']));
						break;
				}
			}
		} else {
			for (let [key, value] of Object.entries(data)) {
				switch (value['cat']) {
					case 1:
						expr.push(key, '#99D5B1');
						break;
					case 2:
						expr.push(key, '#33AB61');
						break;
				}
			}
		}

		expr.push('rgba(0,0,0,0)');
		return expr;
	}, [data, activeLayers]);

	return (
		<Layer
			key={`${mapId}-grid`}
			id={`grid`}
			type={'fill'}
			source={'composite'}
			source-layer={gridSourceLayerName}
			paint={{
				'fill-color': ['case', hoverExpr, '#F79DA3', expr],
				'fill-outline-color': ['case', hoverExpr, '#EF3340', 'rgba(0,0,0,0)'],
				'fill-opacity': 0.7,
			}}
		/>
	);
};
