import styled from 'styled-components';
import * as React from 'react';
import { DomainWrapper } from './Domain';
import { screenSize } from '../../styles/ScreenSize';

export const DomainsGridWrapper = styled.div`
	grid-area: extras-area;
	display: flex;
	flex-wrap: wrap;
	padding: var(--padding) var(--padding) 48px var(--padding);
	background-color: #f2f2f2;

	${screenSize.md} {
		font-size: 16px;
	}

	${screenSize.sm} {
		font-size: 14px;
	}

	h2 {
		flex: 1 0 100%;
		font-size: 24px;
		line-height: 1.3;
		font-weight: bold;
		color: #000;
		margin-bottom: calc(var(--padding) - 8px);

		:not(:first-of-type) {
			margin-top: var(--padding);
		}

		${screenSize.sm} {
			font-size: 18px;
			line-height: 1.3;
		}
	}

	${DomainWrapper} {
		width: calc(100% / 4);

		${screenSize.sm} {
			width: calc(100% / 2);
		}
	}
`;

export const DomainsGrid: React.FC = ({ children }) => {
	return <DomainsGridWrapper>{children}</DomainsGridWrapper>;
};
