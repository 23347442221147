import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { screenSize } from '../../styles/ScreenSize';
import MapIcon from '../../images/map_btn_icon.svg';
import CloseIcon from '../../images/x_bold.svg';
import { Map } from '../map/Map';
import { useMediaLayout } from 'use-media';
import { useSidebarContext } from '../../contexts/SidebarContext';
import { useScrollLock } from '../../hooks/useScrollLock';
import { LocaleContext } from '../../contexts/LocaleContext';
import { getAllLocales } from '../../lib/locale';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
`;

export const MobileModal = styled.div`
	height: calc(100% - var(--nav-bar-height));
	height: calc(fill-available - var(--nav-bar-height));
	background-color: #f2f2f2;
	position: fixed;
	z-index: 90;
	width: 100vw;
	top: var(--nav-bar-height);
	bottom: 0;
	left: 0;
`;

const MobileMap = styled(Map)``;

interface MapButton {
	mapInView: boolean;
	isMobile: boolean;
}

const MapButton = styled.button<MapButton>`
	color: #0072ce;
	background-color: #f2f2f2;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.2;
	letter-spacing: 0.02em;
	border: none;
	border-radius: 5px;
	padding: 5px 8px 5px 8px;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	cursor: pointer;

	:hover {
		background-color: #0072ce;
		color: #fff;

		&&&&&& svg path {
			stroke: #fff;
		}
	}

	${(p) =>
		p.mapInView &&
		!p.isMobile &&
		css`
			color: #fff;
			background-color: #0072ce;

			&&&&&& svg path {
				stroke: #fff;
			}
		`}
	svg {
		height: 20px;
		margin-left: 8px;
	}

	svg path {
		stroke: #0072ce;
	}
`;

const MapButtonText = styled.span`
	margin-top: 0.08em;
`;

const CloseButton = styled.button`
	position: fixed;
	top: calc(var(--nav-bar-height) + 20px);
	right: 10px;
	width: 40px;
	cursor: pointer;
	background: none;
	border: none;
	margin: 0;
	padding: 0;

	:hover svg *,
	:focus svg * {
		fill: #fff;
	}
`;

interface MobileMapModal {
	id: string;
	mapInView: boolean;
}

export const MobileMapModal: React.FC<MobileMapModal> = ({ id, mapInView }) => {
	const [mobileMapOpen, setMobileMapOpen] = useState(false);
	const sidebarContext = useSidebarContext();
	const isMobile = useMediaLayout(screenSize.sm);

	const { t } = useTranslation();

	function toggleMobileMap() {
		{
			isMobile && setMobileMapOpen(!mobileMapOpen);
			!isMobile && sidebarContext.setCurrentElement(<Map id={id} enableFullscreen={true} />);
		}
	}

	return (
		<Wrapper>
			<MapButton onClick={toggleMobileMap} mapInView={mapInView} isMobile={isMobile}>
				<MapButtonText>{t('Kaart')}</MapButtonText>
				<MapIcon aria-hidden="true" />
			</MapButton>
			{mobileMapOpen && <Modal id={id} onClose={toggleMobileMap} />}
		</Wrapper>
	);
};

interface Modal {
	id: string;
	onClose: () => void;
}

function Modal({ id, onClose }: Modal) {
	useScrollLock();

	return (
		<MobileModal>
			<MobileMap id={id} enableFullscreen={false} />
			<CloseButton onClick={onClose}>
				<CloseIcon />
			</CloseButton>
		</MobileModal>
	);
}
