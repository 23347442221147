import { useMapHover } from '../../hooks/useMapHover';

import React, { createContext, FC, RefObject, useContext, useEffect, useRef, useState } from 'react';
import { MapRef } from 'react-map-gl';
import { HoverFeature } from '../../types/maps';
import { UseMapData, useMapData } from './useMapData';
import { useI18next } from 'gatsby-plugin-react-i18next';

interface PopupData {
	position: [number, number];
	sourceData: { [key: string]: any } | null;
}

type PopupDataProps = PopupData | null;

interface MapContext {
	mapId: string;
	mapRef: RefObject<MapRef>;
	setHoverFeatures: (features: HoverFeature[]) => void;
	popUp: PopupDataProps;
	setPopup: (popupData: PopupDataProps) => void;
	mapData: UseMapData;

	sidebarItem: string | null;
	setSidebarItem: (sidebarItem: string | null) => void;

	activeLayers: string[];
	setActiveLayers: (layerIds: string[]) => void;
}

export const MapContext = createContext<MapContext>({} as MapContext);

interface MapContextProvider {
	mapId: string;
}

export const MapContextProvider: FC<MapContextProvider> = ({ children, mapId }) => {
	const { language } = useI18next();
	const mapData = useMapData({ id: mapId, locale: language });
	const mapRef = useRef<MapRef>(null);

	const [activeLayers, setActiveLayers] = useState<string[]>([]);
	const { setHoverFeatures } = useMapHover(mapRef);
	const [popUp, setPopup] = useState<PopupData | null>(null);
	const [sidebarItem, setSidebarItem] = useState<string | null>(null);

	useEffect(() => {
		setActiveLayers([]);
	}, [mapId]);

	useEffect(() => {
		const map = mapRef.current?.getMap();
		if (!map) return;
	}, [mapRef]);

	return (
		<MapContext.Provider
			value={{
				mapRef,
				setHoverFeatures,
				popUp,
				setPopup,
				mapData,
				activeLayers,
				setActiveLayers,
				sidebarItem,
				setSidebarItem,
				mapId,
			}}
		>
			{children}
		</MapContext.Provider>
	);
};

export const useMapContext = () => useContext(MapContext);
