import React from 'react';

import { S13_ut_uhendused } from './S13-ut_uhendused';
import { S00_linnaosade_areng } from './S00-linnaosade-areng';
import { S02_keskused } from './S02_keskused';
import { S04_tombekeskused } from './S04_tombekeskused';
import { S06_tanavatuubid } from './S06_tanavatuubid';
import { S09_rohealad } from './S09_rohealad';
import { S14_rattateede_vork } from './S14_rattateede_vork';
import { S15_ut_ligipaas } from './S15_ut_ligipaas';
import { S20_koolid } from './S20_koolid';
import { S21_ettevotted } from './S21_ettevotted';
import { S22_sundmused } from './S22_sundmused';
import { S28_miljoo } from './S28_miljoo';
import { S31_asumi_sissetulek } from './S31_asumi_sissetulek';
import { S36_kinnisvara_hind } from './S36_kinnisvara_hind';
import { S42_pargid } from './S42_pargid';
import { S43_imbala } from './S43_imbala';

export const layers: Record<string, any> = {
	'S00-linnaosade-areng': <S00_linnaosade_areng />,
	'S02-keskused': <S02_keskused />,
	'S04-tombekeskused': <S04_tombekeskused />,
	'S06-tanavatuubid': <S06_tanavatuubid />,
	'S09-rohealad': <S09_rohealad />,
	'S13-ut_uhendused': <S13_ut_uhendused />,
	'S14-rattateede-vork': <S14_rattateede_vork />,
	'S15-ut-ligipaas': <S15_ut_ligipaas />,
	'S20-koolid': <S20_koolid />,
	'S21-ettevotted': <S21_ettevotted />,
	'S22-sundmused': <S22_sundmused />,
	'S28-miljoo': <S28_miljoo />,
	'S31-asumi-sissetulek': <S31_asumi_sissetulek />,
	'S36-kinnisvara-hind': <S36_kinnisvara_hind />,
	'S42-pargid': <S42_pargid />,
	'S43-imbala': <S43_imbala />,
};
