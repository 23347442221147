import styled from 'styled-components';
import * as React from 'react';
import { screenSize } from '../../styles/ScreenSize';
import { AnnotationSpan } from './Annotation';
import { ReadButton } from './ReadMore';

export const HeroWrapper = styled.div`
	min-height: 90vh;
	padding-bottom: 16px;
	padding-top: 24px;
	box-sizing: border-box;
	flex: 1 0 100%;
`;

export const HeroContent = styled.div`
	width: 50%;

	${AnnotationSpan} {
		svg * {
			fill: #fff;
		}
		:hover {
			color: #fff;
			svg * {
				fill: #fff;
			}
		}
	}

	${ReadButton} {
		background: transparent;
		border: 1px solid black;
		color: black;

		svg * {
			fill: #000;
		}
		:hover {
			background: #fff;
			svg path {
				fill: #fff;
			}
		}
	}

	img {
		display: none;
		${screenSize.sm} {
			display: block;
		}
	}
`;

export const Hero: React.FC = ({ children }) => {
	return (
		<HeroWrapper>
			<HeroContent>{children}</HeroContent>
		</HeroWrapper>
	);
};
