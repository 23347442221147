import styled from 'styled-components';

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
	cursor: pointer;
	margin-left: 10px;
	-webkit-appearance: none;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15px;
	height: 15px;
	border-radius: 2px;
	background: #f2f2f2;
	border: 1px solid #adadad;

	&:checked {
		:after {
			content: url('/assets/icons/check_mark.svg');
		}
	}
`;
