import * as React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { LocalizedLink } from '../LocalizedLink';
import { screenSize } from '../../styles/ScreenSize';
import { AllValdkondMdxQuery } from '../../generated/graphql-types';

interface Domain {
	id: string;
}

export const DomainWrapper = styled.div`
	margin: 8px 0;
	padding-right: 32px;

	${screenSize.sm} {
		padding-right: 16px;
	}
`;

const Content = styled.div`
	font-size: 16px;
	margin-top: 16px;

	@media ${screenSize.sm} {
		display: none;
	}
`;

const StyledLink = styled(LocalizedLink)`
	text-decoration: none;
	color: #0072ce;

	span {
		border-bottom: var(--underline);
	}
`;

export const Domain: React.FC<Domain> = ({ id, children }) => {
	const {
		allMdx: { nodes: domains },
	} = useStaticQuery<AllValdkondMdxQuery>(graphql`
		query AllValdkondMdx {
			allMdx(filter: { frontmatter: { group: { eq: "valdkond" } } }) {
				nodes {
					frontmatter {
						slug
						title
					}
				}
			}
		}
	`);

	const currentDomain = domains.find((domain) => domain.frontmatter?.slug === id);
	if (!currentDomain) return null;

	return (
		<DomainWrapper>
			<StyledLink to={`/${currentDomain.frontmatter?.slug}`}>
				<span>{currentDomain.frontmatter?.title}</span>
			</StyledLink>
			<Content>{children}</Content>
		</DomainWrapper>
	);
};
