import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useSidebarContext } from '../../contexts/SidebarContext';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { screenSize } from '../../styles/ScreenSize';
import { useInView } from 'react-intersection-observer';
import { triggerConfig } from '../../configs/triggerConfig';
import { AllTriggerImagesQuery } from '../../generated/graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';

interface ImageTrigger {
	id: string;
	isDefault?: boolean;
	alt: string;
}

const Wrapper = styled.div``;

const ImageWrapper = styled.div`
	margin: 0 calc(var(--content-padding-right) * -1) 0 calc(var(--padding) * -1);
`;

const GatsbyImg = styled(GatsbyImage)`
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

const GatsbyMobileImg = styled(GatsbyImage)`
	display: none;
	width: 100%;
	margin-bottom: 19px;

	@media ${screenSize.sm} {
		display: inherit;
	}
`;

const MobileImg = styled.img`
	display: none;
	width: 100%;
	margin-bottom: 19px;

	@media ${screenSize.sm} {
		display: inherit;
	}
`;

export const ImageTrigger: React.FC<ImageTrigger> = ({ id, isDefault, children, alt }) => {
	const { setCurrentElement } = useSidebarContext();
	const {
		triggerImages: { nodes: images },
		allFile: { nodes: gifs },
	} = useStaticQuery<AllTriggerImagesQuery>(graphql`
		query AllTriggerImages {
			triggerImages: allFile(filter: { extension: { in: ["jpg", "png"] } }) {
				nodes {
					base
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
			allFile(filter: { extension: { eq: "gif" } }) {
				nodes {
					publicURL
					name
				}
			}
		}
	`);

	// https://gist.github.com/sidharthachatterjee/e0c961fd92ce287dc020939037b915ce
	const currentImage = images.find((image) => image?.base === id);
	const currentGif = gifs.find((gif: { name: string }) => gif?.name + '.gif' === id);
	const [useInViewElement, inView] = useInView(triggerConfig);

	const setSidebarItem = useCallback(() => {
		if (currentGif && currentGif.publicURL) {
			setCurrentElement(<Image src={currentGif.publicURL} alt={alt} />);
		} else if (currentImage?.childImageSharp?.gatsbyImageData) {
			setCurrentElement(<GatsbyImg image={currentImage.childImageSharp.gatsbyImageData} alt={alt} />);
		} else {
			return;
		}
	}, [setCurrentElement]);

	useEffect(() => {
		if (inView || isDefault) {
			setSidebarItem();
		}
	}, [inView]);

	return (
		<Wrapper ref={useInViewElement}>
			{currentImage?.childImageSharp?.gatsbyImageData && (
				<ImageWrapper>
					<GatsbyMobileImg image={currentImage?.childImageSharp?.gatsbyImageData}
						alt={alt}
					/>
				</ImageWrapper>
			)}
			{currentGif?.publicURL && (
				<ImageWrapper>
					<MobileImg src={currentGif.publicURL} alt={alt} />
				</ImageWrapper>
			)}
			{children}
		</Wrapper>
	);
};
