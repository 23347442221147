import React, { useEffect, useState } from 'react';
import LayerIcon from '../../images/filtri_ikoon.svg';
import DownAngleIcon from '../../images/nurk_alla.svg';
import styled from 'styled-components';
import { Checkbox } from '../input/Checkbox';
import { LegendItem } from '../../types/maps';
import { flatten } from 'lodash';
import { useI18next } from 'gatsby-plugin-react-i18next';

//<editor-fold desc="Styled Components">
const LayerHead = styled.button`
	cursor: pointer;
	font-size: 16px;
	line-height: 1.25;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 34px;
	padding: 0 14px;
	color: var(--active-color);
	font-weight: bold;
	border: none;
	background-color: transparent;
	width: 100%;
	text-align: left;

	span {
		flex: 1;
		margin-left: 12px;
	}

	&:focus {
		border: 2px solid var(--active-color);
	}
`;

interface Visibility {
	visible: boolean;
}

const LayerContent = styled.ul<Visibility>`
	border-top: 1px solid #f2f2f2;
	font-size: 12px;
	color: var(--primary-color);
	margin: 0;
	padding: 6px 20px 12px 12px;
	display: ${(p) => (p.visible ? 'list' : 'none')};

	li {
		list-style: none;
		padding: 6px 0;
		margin: 0;
		display: flex;
		align-items: center;

		:before {
			display: none;
		}

		label {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			user-select: none;
			width: 100%;
		}
	}
`;

const LayerWindow = styled.div`
	z-index: 9;
	position: absolute;
	bottom: 14px;
	left: 14px;
	border-radius: 2px;

	min-width: 200px;
	background: #ffffff;
`;

//</editor-fold>

interface MapLegend {
	legend?: LegendItem[][];
	onLayerChange?: (layers: string[]) => void;
}

const ColorBlock = styled.div<{ color?: string }>`
	display: inline-block;
	width: 18px;
	height: 18px;
	margin-right: 13px;
	background-color: ${(p) => p.color};
`;

const IconBlock = styled.img`
	width: 18px;
	height: 18px;
	margin: 4px 13px 4px 0;
`;

const ListItem = styled.li<{ collapsed?: boolean }>`
	&& {
		padding: ${(p) => (p.collapsed ? 0 : '8px 0')};
		line-height: 1;
	}
`;

export const MapLegend: React.FC<MapLegend> = ({ legend, onLayerChange }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [layers, setLayers] = useState<string[]>([]);
	const { t } = useI18next();

	useEffect(() => {
		onLayerChange?.(layers);
	}, [layers]);

	useEffect(() => {
		const checked = flatten(legend)
			?.filter((li) => li.checked === true)
			.map((item) => item?.toggle || '');
		setLayers(checked);
	}, [legend]);

	if (!legend || legend.length === 0) {
		return null;
	}

	function toggleVisibility() {
		setIsOpen((prevState) => !prevState);
	}

	function toggleCheckbox(ev: React.ChangeEvent<HTMLInputElement>) {
		const { name, checked } = ev.target;
		setLayers((prevState) => {
			if (checked) {
				return [name, ...prevState];
			}

			return prevState.filter((item) => item !== name);
		});
	}

	return (
		<LayerWindow aria-hidden="true">
			<LayerHead onClick={toggleVisibility} tabIndex={0}>
				<LayerIcon />
				<span>{t('Kihid')}</span>
				<DownAngleIcon style={{ transform: isOpen ? 'none' : 'rotate(180deg)' }} />
			</LayerHead>
			<LayerContent visible={isOpen}>
				{legend.map((group) => {
					return group.map((legendItem) => {
						const preItem = legendItem.color ? (
							<ColorBlock color={legendItem.color} />
						) : legendItem.icon ? (
							<IconBlock src={`/assets/icons/map/${legendItem.icon}.svg`} />
						) : null;

						const collapsed = (group?.length || 0) > 1;
						const popLabelColor =
							legendItem.toggle === 'pop' && !layers.includes(legendItem.toggle) ? '#666' : 'inherit';

						return (
							<ListItem collapsed={collapsed} key={legendItem.text}>
								<label>
									{preItem}
									<span
										style={{
											flex: '1',
											color: popLabelColor,
										}}
									>
										{legendItem.text}
									</span>
									{!!legendItem.toggle && (
										<Checkbox
											name={legendItem.toggle}
											checked={layers.includes(legendItem.toggle)}
											onChange={toggleCheckbox}
										/>
									)}
								</label>
							</ListItem>
						);
					});
				})}
			</LayerContent>
		</LayerWindow>
	);
};
