import React from 'react';
import { Layer } from 'react-map-gl';
import { hoverExpr } from './constants';
import { SourcesLayers } from '../SourcesLayers';
import { useMapContext } from '../MapContext';

export const S42_pargid: React.FC = () => {
	const {
		mapId,
		mapData: { sources },
	} = useMapContext();

	const layers = {
		'S42-pargid-alad': (
			<Layer
				key={'S42-pargid-alad'}
				id={'S42-pargid-alad'}
				type={'fill'}
				source={'S42-pargid-alad'}
				paint={{
					'fill-color': ['case', hoverExpr, '#F79DA3', 'rgb(51, 171, 97)'],
					'fill-outline-color': ['case', hoverExpr, '#EF3340', 'rgba(0,0,0,0)'],
					'fill-opacity': 0.6,
				}}
			/>
		),

		'S42-pargid-liikumised': (
			<Layer
				key={'S42-pargid-liikumised'}
				id={'S42-pargid-liikumised'}
				type={'line'}
				source={'S42-pargid-liikumised'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#009639'],
					'line-opacity': 1.0,
					'line-width': {
						base: 2,
						stops: [
							[10, 2],
							[12, 2],
							[16, 16],
						],
					},
				}}
			/>
		),
	};

	if (!sources) return null;

	return <SourcesLayers mapId={mapId} sources={sources} layers={layers} />;
};
