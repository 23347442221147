import * as React from 'react';
import styled from 'styled-components';
import { screenSize } from '../../styles/ScreenSize';

export const SuperHeader = styled.p`
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-size: 12px;
	line-height: 1.3;
	margin: 0 0 14px 0;

	${screenSize.sm} {
		margin: 0 0 8px 0;
	}
`;

export const RunningHeader: React.FC = ({ children }) => {
	return <SuperHeader>{children}</SuperHeader>;
};
