import React, { useMemo } from 'react';
import { Expression } from 'mapbox-gl';
import { scaleLinear } from 'd3-scale';
import { Layer } from 'react-map-gl';
import { gridSourceLayerName, hoverExpr } from './constants';
import { useMapContext } from '../MapContext';

export const S43_imbala: React.FC = () => {
	const {
		mapData: { data },
		mapId,
	} = useMapContext();

	const expr = useMemo(() => {
		let expr: Expression = ['match', ['get', 'GRD_INSPIR']];

		if (!data) return 'rgba(0,0,0,0)';

		const colorScale = scaleLinear<string>().domain([0, 0.5, 1]).range(['#E5F5EB', '#19A14D', '#005320']).clamp(true);

		for (let [key, value] of Object.entries(data)) {
			expr.push(key, colorScale(value['val']));
		}

		expr.push('rgba(0,0,0,0)');
		return expr;
	}, [data]);

	return (
		<Layer
			key={`${mapId}-grid`}
			id={'grid'}
			type={'fill'}
			source={'composite'}
			source-layer={gridSourceLayerName}
			paint={{
				'fill-color': ['case', hoverExpr, '#F79DA3', expr],
				'fill-outline-color': ['case', hoverExpr, '#EF3340', 'rgba(0,0,0,0)'],
				'fill-opacity': 0.7,
			}}
		/>
	);
};
