import React from 'react';
import { Layer } from 'react-map-gl';
import { hoverExpr } from './constants';
import { useMapContext } from '../MapContext';
import { SourcesLayers } from '../SourcesLayers';

export const S14_rattateede_vork: React.FC = () => {
	const {
		mapData: { sources },
		mapId,
	} = useMapContext();

	const layers = {
		'S14-rattateede-vork-pohivork': (
			<Layer
				key={'S14-rattateede-vork-pohivork'}
				id={'S14-rattateede-vork-pohivork'}
				type={'line'}
				source={'S14-rattateede-vork-pohivork'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#66AAE2'],
					'line-opacity': 1.0,
					'line-width': {
						base: 2,
						stops: [
							[10, 2],
							[12, 2],
							[16, 32],
						],
					},
				}}
			/>
		),

		'S14-rattateede-vork-tervisevork': (
			<Layer
				key={'S14-rattateede-vork-tervisevork'}
				id={'S14-rattateede-vork-tervisevork'}
				type={'line'}
				source={'S14-rattateede-vork-tervisevork'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#19A14D'],
					'line-opacity': 1.0,
					'line-width': {
						base: 2,
						stops: [
							[10, 2],
							[12, 2],
							[16, 32],
						],
					},
				}}
			/>
		),
	};

	if (!sources) return null;

	return (
		<>
			<SourcesLayers mapId={mapId} sources={sources} layers={layers} />
		</>
	);
};
