import * as React from 'react';
import styled from 'styled-components';

export const ULHeader = styled.p`
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-size: 12px;
	line-height: 1.3;
	margin: 1.45rem 0 0 22px;
`;

export const ListHeader: React.FC = ({ children }) => {
	return <ULHeader>{children}</ULHeader>;
};
