import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { FC, useEffect, useRef, useState } from 'react';
import CloseIcon from '../../images/x_bold.svg';
import PlayIcon from '../../images/play.svg';

import { screenSize } from '../../styles/ScreenSize';
import { useScrollLock } from '../../hooks/useScrollLock';

interface LightBox {
	previewImageUrl: string;
}

export const LightBoxWrapper = styled.div`
	padding: 0;
	justify-content: flex-start;
`;

const Background = styled.div`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	z-index: 50;
	background-color: rgba(242, 242, 242, 0.8);
	padding-left: 150px;
	padding-right: 150px;
	display: flex;
	align-items: center;
	justify-content: center;

	padding-top: 40px;

	${screenSize.sm} {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

const Video = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	width: 100%;

	iframe {
		border: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const WatchVideo = styled.button`
	color: #000;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: 0.02em;
	border: none;
	background: none;
	padding-left: 6px;
	padding-top: 0px;
	display: flex;
`;

const Thumbnail = styled.div`
	box-shadow: inset 0 0 0 1000px rgba(214, 214, 214, 0.51);
	cursor: pointer;
	width: 195px;
	height: 110px;
	border-radius: 5px;
	border: solid;
	border-width: 1px;
	color: #000;
	margin-top: 6px;

	background-size: cover;
	background-position: 50% 50%;

	svg {
		margin-left: 87px;
		margin-top: 38px;
	}
	:hover {
		box-shadow: none;
	}
`;

const CloseButton = styled.div`
	position: fixed;
	top: 65px;
	right: var(--padding);
	width: 40px;
	cursor: pointer;
	background: none;
	border: none;
	margin: 0;
	padding: 0;

	:hover svg * {
		fill: #fff;
	}
	${screenSize.sm} {
		margin-right: 20px;
		svg {
			height: 2em;
		}
	}
`;

export const LightBox: React.FC<LightBox> = ({ children, previewImageUrl }) => {
	const { t } = useTranslation();

	const [visible, setVisible] = useState(false);

	return (
		<LightBoxWrapper>
			<WatchVideo>{t('Vaata videot')}</WatchVideo>

			<Thumbnail
				onClick={() => {
					setVisible(true);
				}}
				style={{
					backgroundImage: `url(${previewImageUrl})`,
				}}
			>
				<PlayIcon />
			</Thumbnail>

			{visible && <BackGroundModal onBackgroundClick={() => setVisible(false)}>{children}</BackGroundModal>}
		</LightBoxWrapper>
	);
};

interface BackGroundModal {
	onBackgroundClick?: () => void;
}
const BackGroundModal: FC<BackGroundModal> = ({ children, onBackgroundClick }) => {
	useScrollLock();

	return (
		<Background onClick={onBackgroundClick}>
			<CloseButton>
				<CloseIcon />
			</CloseButton>
			<Video>{children}</Video>
		</Background>
	);
};
