import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { csv } from 'd3-request';
import { scalePoint } from 'd3-scale';
import styled from 'styled-components';
import { linkHorizontal } from 'd3-shape';
import { DSVParsedArray, DSVRowString } from 'd3-dsv';
import { useResizeEffect } from '../../hooks/useResizeEffect';
import { screenSize } from '../../styles/ScreenSize';
import { useMediaLayout } from 'use-media';
import { AllHorizontalLinkCsvFilesQuery } from '../../generated/graphql-types';
import { useI18next } from 'gatsby-plugin-react-i18next';

//<editor-fold desc="Styled Components">
const Wrapper = styled.div`
	display: flex;
	margin: 12px 0 60px;
	flex-flow: column;
`;

const Column = styled.div`
	position: relative;
	display: flex;
	flex-flow: column;
	flex: 1;
`;

const TitleColumn = styled(Column)`
	font-size: 16px;
	font-weight: bold;
	color: var(--active-color);

	${screenSize.sm} {
		font-size: 11px;
		align-self: flex-end;
	}
`;

interface ColumnText {
	isVisible?: boolean;
}

const ColumnText = styled.div<ColumnText>`
	display: flex;
	align-items: center;
	position: absolute;
	cursor: default;
	transform: translateY(-50%);

	color: ${(p) => (p.isVisible ? 'inherit' : 'var(--grey)')};
`;

const LeftColumnText = styled(ColumnText)`
	text-align: right;
	font-size: 24px;
	line-height: 24px;

	${screenSize.sm} {
		font-size: 14px;
	}
`;

const RightColumnText = styled(ColumnText)`
	font-size: 20px;
	line-height: 20px;

	${screenSize.sm} {
		font-size: 11px;
	}
`;

const Row = styled.div`
	display: flex;
`;

//</editor-fold>

interface HorizontalLink {
	id: string;
	leftTitle?: string;
	rightTitle?: string;
}

interface LinkPos {
	id: string;
	position?: [number, number];
}

interface Link {
	id: string;
	source: LinkPos;
	target: LinkPos;
	value: number;
	selected?: boolean;
}

const allHorizontalLinksCSVFilesQuery = graphql`
	query AllHorizontalLinkCSVFiles {
		allFile(filter: { extension: { eq: "csv" } }) {
			csvFileNodes: nodes {
				name
				publicURL
			}
		}
	}
`;

export const HorizontalLink: React.FC<HorizontalLink> = ({ id, leftTitle, rightTitle }) => {
	const {
		allFile: { csvFileNodes },
	} = useStaticQuery<AllHorizontalLinkCsvFilesQuery>(allHorizontalLinksCSVFilesQuery);
	const { language } = useI18next();

	const node = csvFileNodes.find((node) => {
		const [name, csvLocale] = node.name.split('.');
		return name === id && (csvLocale ? csvLocale === language : true);
	});

	const [wrapperRef, setWrapperRef] = useState<HTMLDivElement>();
	const [wrapperRect, setWrapperRect] = useState<DOMRect>();
	const [data, setData] = useState<DSVParsedArray<DSVRowString>>();
	const [selectedId, setSelectedId] = useState<string | null>(null);

	function fetchData() {
		if (!node?.publicURL) return;

		csv(node.publicURL, (error, data) => {
			setData(data);
		});
	}

	useEffect(fetchData, [id]);

	const updateWrapperRect = useCallback(() => {
		const rect = wrapperRef?.getBoundingClientRect();
		if (rect) {
			setWrapperRect(rect);
		}
	}, [wrapperRef]);

	useEffect(updateWrapperRect, [wrapperRef]);
	useResizeEffect(updateWrapperRect);

	const wrapperRefCallback = useCallback((node: HTMLDivElement) => {
		setWrapperRef(node);

		if (node) {
			updateWrapperRect();
		}
	}, []);

	const isMobile = useMediaLayout(screenSize.sm);

	const [width, height] = useMemo(() => {
		return isMobile ? [wrapperRect?.width || 300, 460] : [wrapperRect?.width || 300, 640];
	}, [wrapperRect]);

	if (!data) return null;

	function selectLayerById(id: string = '') {
		setSelectedId(id);
	}

	function deselectLayer() {
		setSelectedId('');
	}

	const nameColumn = data.columns[0];
	const columns = data.columns.slice(1);

	const heightRange: [number, number] = [8, (height || 0) - 8];

	const leftScale = scalePoint().domain(columns).range(heightRange);
	const rightScale = scalePoint()
		.domain(data.map((d) => d[nameColumn] || ''))
		.range(heightRange);

	const rowLinks: Link[] = [];
	const selectedRowLinks: Link[] = [];

	data.forEach((row) => {
		if (!row) return;
		const name = row[nameColumn];

		Object.keys(row).forEach((key) => {
			if (!key || key === nameColumn) {
				return null;
			}

			const names = [name, key];
			const selected = names.includes(selectedId!) && !!name;

			const link: Link = {
				id: names.join('|').trim(),
				source: {
					id: key,
					position: [0, leftScale(key) || 0],
				},
				target: {
					id: name || '',
					position: [width, rightScale(name!) || 0],
				},
				value: +row[key!]! || 0,
				selected,
			};

			if (selected) {
				selectedRowLinks.push(link);
			} else {
				rowLinks.push(link);
			}
		});
	});

	return (
		<>
			<Wrapper>
				<Row style={{ marginBottom: '20px' }}>
					<TitleColumn style={{ flex: 3, textAlign: 'right' }}>{leftTitle}</TitleColumn>
					<Column style={{ margin: '0  12px', flex: 2 }} />
					<TitleColumn style={{ flex: 3 }}>{rightTitle}</TitleColumn>
				</Row>
				<Row>
					<Column style={{ flex: 3 }}>
						{columns.map((column) => {
							const link = selectedRowLinks.find((v) => v.id.split('|').includes(column || ''));
							const isVisible =
								selectedRowLinks.length === 0 ||
								((link?.value || 0) > 1 && link?.id.split('|').includes(column || '')) ||
								link?.source.id === selectedId;
							return (
								<LeftColumnText
									key={column}
									style={{ right: 0, top: leftScale(column) }}
									onMouseEnter={() => selectLayerById(column)}
									onClick={() => selectLayerById(column)}
									onFocus={() => selectLayerById(column)}
									onMouseLeave={deselectLayer}
									isVisible={isVisible}
									dangerouslySetInnerHTML={{ __html: column }}
									tabIndex={0}
								/>
							);
						})}
					</Column>
					<Column style={{ margin: '0  12px', flex: 2 }} ref={wrapperRefCallback}>
						<svg
							style={{ display: 'block' }}
							width={'100%'}
							height={height}
							viewBox={`0 0 ${width} ${height}`}
							preserveAspectRatio={'none'}
							aria-hidden="true"
						>
							<LinkPaths links={rowLinks} selectedId={selectedId} />
							<LinkPaths links={selectedRowLinks} selectedId={selectedId} />
						</svg>
					</Column>

					<Column style={{ flex: 3 }}>
						{data.map((row) => {
							const name = row[nameColumn] || '';
							const link = selectedRowLinks.find((v) => v.id.split('|').includes(name || ''));
							const isVisible =
								selectedRowLinks.length === 0 ||
								((link?.value || 0) > 1 && link?.id.split('|').includes(name || '')) ||
								link?.target.id === selectedId;
							return (
								<RightColumnText
									key={name}
									style={{ left: 0, top: rightScale(name) }}
									onMouseEnter={() => selectLayerById(name)}
									onClick={() => selectLayerById(name)}
									onFocus={() => selectLayerById(name)}
									onMouseLeave={deselectLayer}
									isVisible={isVisible}
									dangerouslySetInnerHTML={{ __html: name }}
									tabIndex={0}
								/>
							);
						})}
					</Column>
				</Row>
			</Wrapper>
		</>
	);
};

interface LinkPaths {
	links: Link[];
	selectedId: string | null;
}

const strokeWidthMap: { [key: number]: number } = {
	0: 0,
	1: 1,
	2: 2,
	3: 6,
};

function getStrokeWidth(weight: number): number {
	return strokeWidthMap[weight] || 0;
}

function LinkPaths({ links, selectedId }: LinkPaths) {
	const hLink = linkHorizontal<Link, LinkPos>()
		.x((d) => d.position?.[0] || 0)
		.y((d) => d.position?.[1] || 0);
	const isAnySelected = !!selectedId;

	return (
		<>
			{links.map((link) => {
				if ((link.value <= 1 && !link.selected) || link.value <= 1) return null;

				const draw = hLink(link);
				if (!draw) return null;

				return (
					<path
						key={link.id}
						style={{
							transition: 'all 200ms',
							fill: 'none',
							stroke: link.selected || !isAnySelected ? '#0072ce' : '#ddedf9',
							strokeWidth: isAnySelected && link.selected ? `${getStrokeWidth(link.value)}px` : '1px',
						}}
						d={draw}
					/>
				);
			})}
		</>
	);
}
