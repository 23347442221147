import * as React from 'react';
import { CSSProperties, FC, ReactElement, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import 'mapbox-gl/dist/mapbox-gl.css';

import { FullscreenControl, MapNavigationControl, MapScaleControl } from './MapControls';
import { renderString } from 'nunjucks';
import { ExtraState, InteractiveMap, MapEvent, ViewState } from 'react-map-gl';
import { clamp, isEmpty, throttle } from 'lodash';

import { MapLegend } from './MapLegend';
import { MapTitle } from './MapTitle';
import { Popup } from './Popup';
import { MapContextProvider, useMapContext } from './MapContext';
import './NunjucksConfig';
import { gridSourceLayerName } from './layers/constants';
import { MapLayers } from './MapLayers';

//<editor-fold desc="Styled Components">
interface Wrapper {
	fullscreen?: boolean;
}

const Wrapper = styled.figure<Wrapper>`
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row;
	position: relative;

	${(p) =>
		p.fullscreen &&
		css`
			position: fixed;
			width: 100vw;
			right: 0;
			top: 0;
			bottom: 0;
		`}
	.mapboxgl-ctrl-logo {
		display: none;
	}
`;

const Caption = styled.figcaption`
	height: 0;
	padding: 0;
	position: absolute;
`;
//</editor-fold>

function getGridItemId(ev: MapEvent): string {
	const gridFeature = ev.features?.find((f) => f.source === 'composite' || f.sourceLayer === gridSourceLayerName);
	return gridFeature?.properties?.GRD_INSPIR || '';
}

const defaultMapProps = {
	latitude: 59.437,
	longitude: 24.746,
	zoom: 10.5,
	maxZoom: 14,
	minZoom: 10,
};

interface Map {
	className?: string;
	style?: CSSProperties;
	id: string;
	enableFullscreen?: boolean;
	sidebar?: ReactElement;
}

export const Map: FC<Map> = (mapProps) => {
	return (
		<MapContextProvider mapId={mapProps.id}>
			<RenderMap {...mapProps} />
		</MapContextProvider>
	);
};

export const RenderMap: React.FC<Map> = ({ className, style, enableFullscreen, children, sidebar }) => {
	const {
		mapRef,
		setPopup,
		setHoverFeatures,
		popUp,
		mapData: { sources, meta, data },
		setActiveLayers,
		activeLayers,
	} = useMapContext();

	const [viewState, setViewState] = useState<ViewState>();
	const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
	const initialMapProps = useMemo(() => ({ ...defaultMapProps, ...meta?.viewState }), [meta]);

	const interactiveLayerIds = useMemo(() => {
		const sourcesArray = sources?.map((s) => s.name) || [];
		if (meta?.useGrid) sourcesArray.push('grid');

		return sourcesArray;
	}, [sources, meta]);

	// Missing types
	const handleViewportChange = (info: any) => {
		if (meta?.maxBounds) {
			const [lngMax, latMax] = meta.maxBounds[0];
			const [lngMin, latMin] = meta.maxBounds[1];

			info.viewState.longitude = clamp(lngMin, lngMax, info.viewState.longitude);
			info.viewState.latitude = clamp(latMin, latMax, info.viewState.latitude);
		}

		setViewState(info.viewState);
	};

	const handleHover = useCallback(
		throttle((ev: MapEvent) => {
			const allSources = ev.features?.filter((feature) => feature.source && !isEmpty(feature.properties)) || [];

			const sourceData = allSources.reduce((obj, feature) => {
				const objData =
					feature.source === 'composite' && feature.sourceLayer === gridSourceLayerName
						? data?.[getGridItemId(ev)]
						: feature.properties;

				if (!objData) {
					return obj;
				}

				obj[feature.source] = objData;
				return obj;
			}, {});

			if (isEmpty(sourceData)) {
				setPopup(null);
				setHoverFeatures([]);
				return;
			}

			setHoverFeatures(allSources);
			setPopup({ position: ev.point, sourceData });
		}, 60),
		[data]
	);

	function handleLayerChange(layers: string[]) {
		setActiveLayers(layers);
	}

	function handleClick(ev: MapEvent) {
		ev.features?.forEach((feature) => {
			const interactiveLayer = meta?.interactionLayers?.find((il) => il.id === feature.source);

			if (interactiveLayer && feature.properties.hasOwnProperty(interactiveLayer.property)) {
				setActiveLayers([feature.properties[interactiveLayer.property]]);
			}
		});
	}

	function toggleFullscreen() {
		setIsFullscreen((prevValue) => !prevValue);
	}

	const template = meta?.popup?.template;
	const color = meta?.popup?.color;
	const popupColor = renderString(color || '', {
		...meta?.popup?.data,
		sources: popUp?.sourceData,
		activeLayers,
	});
	const popupMessage = renderString(template || '', {
		...meta?.popup?.data,
		sources: popUp?.sourceData,
		activeLayers,
	});

	return (
		<Wrapper className={className} style={style} fullscreen={isFullscreen} aria-labelledby={meta?.name}>
			<Caption>{meta?.caption}</Caption>
			<InteractiveMap
				ref={mapRef}
				mapboxApiAccessToken={process.env.MAPBOX_API_TOKEN}
				{...initialMapProps}
				viewState={viewState}
				mapStyle="mapbox://styles/tln2030/ck6ar6wsv1uay1imrwxbn4pqo"
				width="100%"
				height="100%"
				onViewStateChange={handleViewportChange}
				onClick={handleClick}
				onHover={handleHover}
				interactiveLayerIds={interactiveLayerIds}
				dragRotate={false}
				attributionControl={true}
				disableTokenWarning={true}
				reuseMaps={false}
				preventStyleDiffing={false}
				getCursor={getCursor}
				clickRadius={meta?.clickRadius || 0}
			>
				<MapLayers />

				{enableFullscreen && <FullscreenControl onClick={toggleFullscreen} />}
				{children}

				<MapScaleControl />
				<MapNavigationControl />
				{popUp?.sourceData && popupMessage && (
					<Popup position={popUp.position} icon={popupColor}>
						<div dangerouslySetInnerHTML={{ __html: popupMessage }} />
					</Popup>
				)}
			</InteractiveMap>
			<MapTitle meta={meta} />
			<MapLegend legend={meta?.legend} onLayerChange={handleLayerChange} />
			{sidebar}
		</Wrapper>
	);
};

function getCursor({ isDragging }: ExtraState) {
	return isDragging ? 'move' : 'default';
}
