import React from 'react';
import { useMemo } from 'react';
import { layers } from './layers/layers';
import { useMapContext } from './MapContext';

export const MapLayers = () => {
	const {
		mapId,
		mapData: { sources },
	} = useMapContext();

	return useMemo(() => {
		return layers[mapId];
	}, [mapId, sources]);
};
