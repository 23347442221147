import { useCallback, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MapMeta, MapSource } from '../../types/maps';
import { AllMapsRelatedFilesQuery } from '../../generated/graphql-types';

const FILES_QUERY = graphql`
	query AllMapsRelatedFiles {
		geojsonFiles: allFile(filter: { relativePath: { glob: "**/kaardid/**/*.geojson" } }) {
			nodes {
				name
				publicURL
			}
		}
		dataFiles: allFile(filter: { relativePath: { glob: "**/kaardid/**/*.data.json" } }) {
			nodes {
				name
				publicURL
			}
		}
		metaFiles: allFile(filter: { relativePath: { glob: "**/kaardid/**/*.meta?(.*).json" } }) {
			nodes {
				name
				publicURL
			}
		}
	}
`;

interface UseMapDataProps {
	id: string;
	locale: string;
}

export interface UseMapData {
	data?: Record<string, any>;
	meta?: MapMeta | null;
	sources?: MapSource[];
}

const localeRegex = /\.([a-z]{2})$/i;

export function useMapData({ id, locale }: UseMapDataProps): UseMapData {
	const [data, setData] = useState<Record<string, any>>();
	const [meta, setMeta] = useState<MapMeta | null>(null);
	const [sources, setSources] = useState<MapSource[]>();

	const {
		geojsonFiles: { nodes: geojsonFiles },
		dataFiles: { nodes: dataFiles },
		metaFiles: { nodes: metaFiles },
	} = useStaticQuery<AllMapsRelatedFilesQuery>(FILES_QUERY);

	const findByIdAndLocale = useCallback(
		(node: { name: string }) => {
			const [_, nodeLocale] = localeRegex.exec(node.name) || [[], null];
			return node.name.split('.')[0] === id && (nodeLocale ? nodeLocale === locale : true);
		},
		[locale, id]
	);

	// function findById(node: { name: string }) {
	// 	const [_, nodeLocale] = localeRegex.exec(node.name) || [[], null];
	// 	return (node.name.split('.')[0] === id) && (nodeLocale ? nodeLocale === locale : true);
	// }

	function updateMeta() {
		const metaFile = metaFiles.find(findByIdAndLocale);
		if (!metaFile?.publicURL) return;

		fetch(metaFile.publicURL)
			.then((r) => r.json())
			.then(setMeta);
	}

	function updateData() {
		const dataFile = dataFiles.find(findByIdAndLocale);
		if (!dataFile?.publicURL) return;
		fetch(dataFile.publicURL)
			.then((r) => r.json())
			.then(setData);
	}

	function updateSources() {
		// Find and order geojson data by order from meta
		const mapSources = meta?.sources?.reduce<MapSource[]>((prev, current) => {
			const geojsonFile = geojsonFiles.find(({ name }) => `${name}.geojson` === current);
			if (geojsonFile) {
				return [...prev, geojsonFile as MapSource];
			}
			return prev;
		}, []);
		setSources(mapSources);
	}

	useEffect(() => {
		updateSources();
	}, [meta]);

	useEffect(() => {
		updateMeta();
		updateData();
	}, [id]);

	return {
		data,
		meta,
		sources,
	};
}
