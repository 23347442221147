import React, { useMemo } from 'react';
import { Expression } from 'mapbox-gl';
import { Layer } from 'react-map-gl';
import { gridSourceLayerName, hoverExpr } from './constants';
import { useMapContext } from '../MapContext';

export const S21_ettevotted: React.FC = () => {
	const {
		mapData: { data },
		mapId,
	} = useMapContext();

	const expr = useMemo(() => {
		let expr: Expression = ['match', ['get', 'GRD_INSPIR']];

		if (!data) return 'rgba(0,0,0,0)';

		for (let [key, value] of Object.entries(data)) {
			switch (value['cat']) {
				case 1:
					expr.push(key, 'hsl(356, 85%, 80%)');
					break;
				case 2:
					expr.push(key, '#EF3340');
					break;
			}
		}

		expr.push('rgba(0,0,0,0)');
		return expr;
	}, [data]);

	return (
		<Layer
			key={`${mapId}-grid`}
			id={'grid'}
			type={'fill'}
			source={'composite'}
			source-layer={gridSourceLayerName}
			paint={{
				'fill-color': ['case', hoverExpr, '#F79DA3', expr],
				'fill-outline-color': ['case', hoverExpr, '#EF3340', 'rgba(0,0,0,0)'],
				'fill-opacity': 0.7,
			}}
		/>
	);
};
