import { Hero } from '../components/content/Hero';
import { Content } from '../components/content/Content';
import { ReadMore } from '../components/content/ReadMore';
import { Annotation } from '../components/content/Annotation';
import { ImageTrigger } from '../components/content/ImageTrigger';
import { MapTrigger } from '../components/content/MapTrigger';
import { DomainsGrid } from '../components/content/DomainsGrid';
import { Domain } from '../components/content/Domain';
import { Chart } from '../components/content/Chart';
import { Meter } from '../components/content/Meter';
import { MeterData } from '../components/content/MeterData';
import { MoreInfo } from '../components/content/MoreInfo';
import { RunningHeader } from '../components/content/RunningHeader';
import { ListHeader } from '../components/content/ListHeader';
import { HorizontalLink } from '../components/charts/HorizontalLink';
import { Link } from '../components/content/Link';
import { LightBox } from '../components/content/LightBox';
import { ImageGrid } from '../components/content/ImageGrid';
import { ThumbNailImg } from '../components/content/ThumbNailImg';
import { Video } from '../components/content/Video';

export const userShortcodes: { [key: string]: any } = {
	Hero,
	Content,
	ReadMore,
	Annotation,
	ImageTrigger,
	MapTrigger,
	DomainsGrid,
	Domain,
	Chart,
	Meter,
	RunningHeader,
	ListHeader,
	HorizontalLink,
	MoreInfo,
	MeterData,
	a: Link,
	LightBox,
	ImageGrid,
	ThumbNailImg,
	Video,
};
