import * as React from 'react';
import styled from 'styled-components';
import { screenSize } from '../../styles/ScreenSize';

const Wrapper = styled.span`
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 1.2;

	${screenSize.sm} {
		font-size: 18px;
		line-height: 1.3;
	}

	:not(:last-child) {
		margin-right: 16px;
	}

	p {
		display: inline-block;
		margin-bottom: 12px;
	}
`;

export const MeterData: React.FC = ({ children }) => {
	return <Wrapper>{children}</Wrapper>;
};
