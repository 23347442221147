import * as React from 'react';
import styled from 'styled-components';
import { ContentWrapper } from './Content';
import { screenSize } from '../../styles/ScreenSize';

const MeterWrapper = styled.div`
	font-weight: normal;
	font-style: normal;
	font-size: 36px;
	line-height: 1.2;
	padding-top: 12px;

	&:not(:last-child) {
		padding-bottom: 24px;
	}

	${screenSize.sm} {
		font-size: 28px;
		line-height: 1.1;
	}

	${ContentWrapper} & {
		color: var(--active-color);
		font-weight: normal;

		img {
			margin: 0 calc(var(--content-padding-right) * -1) calc(var(--padding) * 0) calc(var(--padding) * -1);
		}

		:last-child > img {
			margin: 0 calc(var(--content-padding-right) * -1) 0 calc(var(--padding) * -1);
		}
	}
`;

const Title = styled.div`
	font-size: 20px;
	font-style: normal;
	font-weight: bold;
	line-height: 1.2;
	letter-spacing: 0.02em;
	margin-bottom: 12px;

	${screenSize.sm} {
		font-size: 18px;
		line-height: 1.3;
	}
`;

interface Meter {
	title: string;
}

export const Meter: React.FC<Meter> = ({ children, title }) => {
	return (
		<MeterWrapper>
			{title && <Title>{title}</Title>}
			{children}
		</MeterWrapper>
	);
};
