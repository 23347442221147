import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GroupedBarChart } from '../charts/GroupedBarChart';
import { AllChartCsvFilesQuery } from '../../generated/graphql-types';

interface Chart {
	id: string;
}

export const Chart: React.FC<Chart> = ({ id }) => {
	const {
		allFile: { nodes },
	} = useStaticQuery<AllChartCsvFilesQuery>(graphql`
		query AllChartCSVFiles {
			allFile(filter: { extension: { eq: "csv" } }) {
				nodes {
					name
					publicURL
				}
			}
		}
	`);

	// filter for required csv file
	const csvFile = nodes.find((node) => node.name === id);
	if (!csvFile?.publicURL) return null;
	return <GroupedBarChart file={csvFile.publicURL} />;
};
