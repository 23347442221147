import * as React from 'react';
import styled from 'styled-components';
import { screenSize } from '../../styles/ScreenSize';

export const ContentWrapper = styled.div`
	padding: 28px var(--content-padding-right) 28px var(--padding);
	max-width: 50vw;

	h2 {
		font-weight: normal;
		color: #0072ce;
		border-top: 1px solid #0072ce;
		margin: 0 calc(var(--content-padding-right) * -1) 0 calc(var(--padding) * -1);
		padding: 24px var(--padding) 16px var(--padding);

		&:first-child {
			padding-top: 0;
			margin-top: 0;
			border-top: none;
		}
	}

	ol > li:first-of-type {
		margin-top: 32px;

		${screenSize.sm} {
			margin-top: 0;
		}
	}

	div + p:first-of-type {
		margin-top: 20px;
	}

	& > div {
		padding-bottom: 56px;

		a:last-child {
			margin-bottom: 0;
		}
	}

	${screenSize.sm} {
		max-width: 100vw;
	}
`;

export const Content: React.FC = ({ children }) => {
	return <ContentWrapper>{children}</ContentWrapper>;
};
