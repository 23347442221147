import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { userShortcodes } from '../../lib/userShortcodes';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import InfoIcon from '../../images/info_ikoon_medium.svg';
import CloseIcon from '../../images/x_kinni.svg';
import { screenSize } from '../../styles/ScreenSize';
import { AllMoistedYamlQuery } from '../../generated/graphql-types';

//<editor-fold desc="Styled Components">
const Wrapper = styled.span`
	position: relative;
	${screenSize.sm} {
		position: initial;
	}
`;

export const AnnotationSpan = styled.span`
	text-decoration: none;
	cursor: pointer;
	font-weight: bold;
	outline: none;

	:focus {
		border: 1px solid;
	}

	:hover {
		color: var(--active-color);
	}

	:hover svg * {
		fill: var(--active-color);
	}

	svg {
		height: 0.8em;
		margin: 0 3px -2px 3px;
	}
`;

const Popup = styled.span`
	position: absolute;
	background-color: #d6d6d6;
	color: #000;
	padding: 16px 48px 16px 16px;
	width: 400px;
	font-size: 16px;
	z-index: 900;
	left: 0;

	${screenSize.sm} {
		width: 100%;
	}

	svg {
		position: absolute;
		height: 22px;
		top: 14px;
		right: 14px;
		cursor: pointer;
	}
	svg * {
		fill: #000;
	}
	svg:hover * {
		fill: var(--hover-color);
	}
`;
//</editor-fold>

interface Notion {
	id: string;
}

export const Annotation: React.FC<Notion> = ({ children, id }) => {
	const [visible, setVisible] = useState(false);

	const {
		allMoistedYaml: { nodes },
	} = useStaticQuery<AllMoistedYamlQuery>(graphql`
		query AllMoistedYaml {
			allMoistedYaml {
				nodes {
					yamlId
					content
				}
			}
		}
	`);

	const relatedAnnotation = nodes.find((annotation) => annotation.yamlId === id);

	function show() {
		setVisible(true);
	}

	function hide() {
		setVisible(false);
	}

	return (
		<Wrapper>
			<AnnotationSpan tabIndex={0} onFocus={show} onBlur={hide} onClick={(ev) => ev.preventDefault()}>
				{children}
				<InfoIcon />
			</AnnotationSpan>
			{visible && relatedAnnotation && (
				<Popup>
					<CloseIcon />
					<MDXProvider components={userShortcodes}>
						<MDXRenderer>{relatedAnnotation?.content || ''}</MDXRenderer>
					</MDXProvider>
				</Popup>
			)}
		</Wrapper>
	);
};
