import * as React from 'react';
import { useEffect, useState } from 'react';
import { scaleBand, scaleLinear, scaleOrdinal } from 'd3-scale';
import { max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { select } from 'd3-selection';
import { csv } from 'd3-request';
import styled from 'styled-components';
import { DSVParsedArray, DSVRowString } from 'd3-dsv';

const Rect = styled.rect``;

const SVGCanvas = styled.svg`
	width: 100%;
	max-height: 460px;
`;

const defaultColors = ['#98abc5', '#8a89a6', '#7b6888', '#6b486b', '#a05d56', '#d0743c', '#ff8c00'];

interface GroupedBarChart {
	file: string;
}

export const GroupedBarChart = ({ file }: GroupedBarChart) => {
	if (!file) return null;

	const [csvData, setCsvData] = useState<DSVParsedArray<DSVRowString>>();

	function fetchCSVData() {
		csv(file, (error, data) => {
			if (error) {
				console.error(error);
			}
			setCsvData(data);
		});
	}

	useEffect(fetchCSVData, [file]);

	if (!csvData) return null;

	const headers = csvData.columns;
	const keys = headers.slice(1);
	const data = csvData.map((d) => ({
		group: d[headers[0]],
		items: keys.map((key) => ({ key, value: d[key] })),
	}));
	const svgWidth = 400,
		svgHeight = 400;

	const margin = { top: 20, right: 20, bottom: 30, left: 40 },
		width = svgWidth - margin.left - margin.right,
		height = svgHeight - margin.top - margin.bottom;

	const x0 = scaleBand().rangeRound([0, width]).padding(0.1),
		x1 = scaleBand().padding(0.05),
		y = scaleLinear().rangeRound([height, margin.top]);

	const colorScale = scaleOrdinal(defaultColors);

	x0.domain(csvData.map((d) => d[headers[0]]));
	x1.domain(keys).rangeRound([0, x0.bandwidth()]);

	const maxValue = max(csvData, (d) =>
		max(keys, (key: string) => {
			return parseFloat(d[key]);
		})
	);
	y.domain([0, maxValue]).nice();

	return (
		<SVGCanvas viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
			<g transform={`translate(${margin.left}, ${margin.top})`}>
				<g
					className="axis axis--x"
					transform={`translate(0, ${height})`}
					ref={(node) => select(node).call(axisBottom(x0))}
				/>
				<g className="axis axis--y">
					<g ref={(node) => select(node).call(axisLeft(y).ticks(10))} />
					<text transform="rotate(-90)" y="6" dy="0.71em" textAnchor="end">
						Total
					</text>
				</g>
				{keys.map((key, i) => (
					<g key={key} className="legend" transform={`translate(${width},0) translate(0,${i * 20})`} textAnchor="end">
						<rect x={-19} width={19} height={19} fill={colorScale(key)} />
						<text x={-24} y={9.5} dy="0.35em">
							{key}
						</text>
					</g>
				))}
				{data.map((dat) => (
					<g key={dat.group} transform={`translate(${x0(dat.group)},0)`}>
						{dat.items.map((d) => (
							<Rect
								key={d.key}
								x={x1(d.key)}
								y={y(d.value)}
								width={x1.bandwidth()}
								height={height - y(d.value)}
								fill={colorScale(d.key)}
							/>
						))}
					</g>
				))}
			</g>
		</SVGCanvas>
	);
};
