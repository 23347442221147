import * as React from 'react';
import styled from 'styled-components';
import { LocalizedLink } from '../LocalizedLink';
import { useLocation } from '@reach/router';
import ReadIcon from '../../images/loe_ikoon.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

//<editor-fold desc="Styled Components">
const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;

	p + & {
		margin-top: -4px;
	}
`;

const StyledLink = styled(LocalizedLink)`
	text-decoration: none;
	margin-bottom: 25px;
`;

export const ReadButton = styled.button`
	color: #0072ce;
	background-color: var(--button-grey);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: 0.02em;
	border: none;
	border-radius: 5px;
	padding: 4px 8px 4px 8px;

	display: flex;
	vertical-align: center;
	cursor: pointer;

	:hover {
		background-color: #0072ce;
		color: #fff;
		fill: #fff;
	}

	:hover svg * {
		fill: #fff;
	}

	svg {
		height: 16px;
		margin-left: 8px;
		margin-top: 3px;
	}
`;
//</editor-fold>

interface ReadMoreProps {
	id: string;
}

export const ReadMore: React.FC<ReadMoreProps> = ({ id, children }) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();

	return (
		<Wrapper>
			<StyledLink id={id} to={`${pathname}/${id}`} state={{ parent: true }}>
				<ReadButton>
					{children || t('Loe lisa')}
					<ReadIcon aria-hidden="true" />
				</ReadButton>
			</StyledLink>
		</Wrapper>
	);
};
