import * as React from 'react';
import styled from 'styled-components';

interface ImageGridProps {
	maxWidth?: number;
}

export const ImageGrid = styled.div<ImageGridProps>`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	margin-right: 22px;
	margin-left: 22px;
	margin-top: 9px;
	padding: 0px;

	span {
		width: ${(p) => p.maxWidth ?? '100%'};
	}
`;
