import { useLayoutEffect } from 'react';

export function useScrollLock() {
	useLayoutEffect(() => {
		const htmlElement = document.getElementsByTagName('html')[0];
		const initialBodyOverflow = window.getComputedStyle(document.body).overflow;
		const initialHtmlOverflow = window.getComputedStyle(htmlElement).overflow;

		document.body.style.overflow = 'hidden';
		htmlElement.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = initialBodyOverflow;
			htmlElement.style.overflow = initialHtmlOverflow;
		};
	}, []);
}
