import styled, { css } from 'styled-components';

interface POILabel {
	isActive?: boolean;
}

export const POILabel = styled.div<POILabel>`
	position: absolute;
	background: #ffffff;
	box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	padding: 4px 7px;
	color: #0072ce;
	transform: translate(-50%, -200%);
	cursor: pointer;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	display: flex;
	align-items: center;

	${(p) =>
		p.isActive &&
		css`
			background: #f25c66;
			color: #ffffff;

			svg {
				fill: #ffffff;
			}
		`}
`;
