import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { hoverExpr } from './constants';
import { SourcesLayers } from '../SourcesLayers';
import { useMapContext } from '../MapContext';

export const S02_keskused: React.FC = () => {
	const {
		mapId,
		mapData: { sources },
	} = useMapContext();

	const layers = {
		'S02-keskused-linnaregioon_poly': (
			<Layer
				key={'S02-keskused-linnaregioon_poly'}
				id={'S02-keskused-linnaregioon_poly'}
				type={'fill'}
				source={'S02-keskused-linnaregioon_poly'}
				paint={{
					'fill-color': ['case', hoverExpr, '#F79DA3', '#66AAE2'],
					'fill-opacity': 0.6,
				}}
			/>
		),

		'S02-keskused-keskused_pt': (
			<Layer
				key={'S02-keskused-keskused_pt'}
				id={'S02-keskused-keskused_pt'}
				type={'circle'}
				source={'S02-keskused-keskused_pt'}
				paint={{
					'circle-color': ['case', hoverExpr, '#F79DA3', '#66AAE2'],
					'circle-opacity': 0.8,
					'circle-stroke-color': ['case', hoverExpr, '#EF3340', '#0072CE'],
					'circle-stroke-width': 1.8,
				}}
			/>
		),
	};

	if (!sources) return null;

	return (
		<>
			<Source
				key={'S02-skeem-source'}
				id={'S02-skeem'}
				type={'image'}
				url={'/assets/icons/map/S02-skeem.png'}
				coordinates={[
					[24.52276, 59.533987],
					[24.993089, 59.533987],
					[24.993089, 59.334427],
					[24.522174, 59.334726],
				]}
			>
				<Layer
					key={'S02-skeem'}
					id={'S02-skeem'}
					type={'raster'}
					source={'S02-skeem'}
					paint={{
						'raster-opacity': 0.6,
					}}
				/>
			</Source>

			<SourcesLayers mapId={mapId} sources={sources} layers={layers} />
		</>
	);
};
