export const gridSourceLayerName = 'grid-ids-av0ns0';

export const hoverExpr = [
	'all',
	['boolean', ['feature-state', 'hover'], false],
	['==', ['get', 'id'], ['feature-state', 'id']],
];

export const activeExpr = ['boolean', ['feature-state', 'highlighted'], false];
// ['==', ['get', 'id'], ['feature-state', 'id']],
