import React, { useMemo } from 'react';
import { scalePow } from 'd3-scale';
import { Layer } from 'react-map-gl';
import { hoverExpr } from './constants';
import { useMapContext } from '../MapContext';
import { SourcesLayers } from '../SourcesLayers';

export const S36_kinnisvara_hind: React.FC = () => {
	const {
		mapId,
		mapData: { sources },
	} = useMapContext();

	const expr = useMemo(() => {
		let expr = [];
		const colorScale = scalePow<string>().domain([0, 3000]).exponent(3).range(['#DFECF6', '#0072CE']).clamp(true);

		for (let i = 0; i <= 3000; i += 250) {
			expr.push(i, colorScale(i));
		}
		return expr;
	}, []);

	const layers = {
		'S36-kinnisvara-hind': (
			<Layer
				key={'S36-kinnisvara-hind'}
				id={'S36-kinnisvara-hind'}
				type={'fill'}
				source={'S36-kinnisvara-hind'}
				paint={{
					'fill-color': [
						'case',
						hoverExpr,
						'#F79DA3',
						[
							'case',
							['==', ['get', 'asumid_2019_average_price'], -1],
							'#999999',
							['interpolate', ['linear'], ['get', 'asumid_2019_average_price'], ...expr],
						],
					],
					'fill-outline-color': ['case', hoverExpr, '#EF3340', 'rgba(0,0,0,0)'],
					'fill-opacity': 0.7,
				}}
			/>
		),
	};

	if (!sources) return null;

	return <SourcesLayers mapId={mapId} sources={sources} layers={layers} />;
};
