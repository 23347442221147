import * as React from 'react';
import { useEffect } from 'react';
import { useSidebarContext } from '../../contexts/SidebarContext';
import { Map } from '../map/Map';
import { useMediaLayout } from 'use-media';
import { useInView } from 'react-intersection-observer';

import { MobileMapModal } from './MobileMapModal';
import { screenSize } from '../../styles/ScreenSize';
import { triggerConfig } from '../../configs/triggerConfig';

interface MapTrigger {
	id: string;
	isDefault?: boolean;
}

export const MapTrigger: React.FC<MapTrigger> = ({ id, isDefault, children }) => {
	const { setCurrentElement } = useSidebarContext();
	const isMobile = useMediaLayout(screenSize.sm);
	const [useInViewElement, inView] = useInView(triggerConfig);

	useEffect(() => {
		if (inView || isDefault) {
			!isMobile && setCurrentElement(<Map id={id} enableFullscreen={true} />);
		}
	}, [inView, setCurrentElement]);

	return (
		<div ref={useInViewElement}>
			{id && <MobileMapModal id={id} mapInView={inView} />}
			{children}
		</div>
	);
};
