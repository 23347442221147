import * as React from 'react';
import styled from 'styled-components';

export const VideoWrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	iframe {
		border: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export const Video: React.FC = ({ children }) => {
	return <VideoWrapper>{children}</VideoWrapper>;
};
