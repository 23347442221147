import { NavigationControl, ScaleControl } from 'react-map-gl';
import * as React from 'react';
import styled from 'styled-components';
import FullscreenIcon from '../../images/fullscreen.svg';

const Scale = styled.div`
	position: absolute;
	bottom: 28px;
	right: 14px;

	& > div {
		bottom: 0;
		right: 0;
	}

	.mapboxgl-ctrl.mapboxgl-ctrl-scale {
		font-size: 12px;
		line-height: 14px;
		color: var(--active-color);
		border-color: var(--active-color);
	}
`;

const Navigation = styled.div`
	position: absolute;
	bottom: 56px;
	right: 14px;

	& > div {
		bottom: 0;
		right: 0;
	}

	.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
		background-image: url('/assets/icons/zoomin.svg');
	}
	.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
		background-image: url('/assets/icons/zoomout.svg');
	}
	.mapboxgl-ctrl.mapboxgl-ctrl-group {
		border-radius: 2px;
	}
	.mapboxgl-ctrl-group:not(:empty) {
		box-shadow: none;
	}
	.mapboxgl-ctrl-group button + button {
		border-top: 1px solid #f2f2f2;
	}
`;

export const MapScaleControl = () => (
	<Scale aria-hidden="true">
		<ScaleControl maxWidth={100} unit={'metric'} />
	</Scale>
);

export const MapNavigationControl = () => (
	<Navigation aria-hidden="true">
		<NavigationControl showCompass={false} />
	</Navigation>
);

const FullscreenControlButton = styled.button`
	background: white;
	padding: 2px;
	border-radius: 2px;
	border: none;
	height: 29px;
	width: 29px;
	align-items: center;
	bottom: 118px;
	right: 14px;
	position: absolute;
	outline: none;
	cursor: pointer;

	svg {
		width: 100%;
		height: 100%;
	}
	:hover svg {
		transform: scale(1.2);
	}
`;

interface FullscreenControl {
	onClick?: () => void;
}

export const FullscreenControl: React.FC<FullscreenControl> = ({ onClick }) => {
	return (
		<FullscreenControlButton aria-hidden="true" onClick={onClick} tabindex="0">
			<FullscreenIcon />
		</FullscreenControlButton>
	);
};
