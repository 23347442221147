import React, { FC } from 'react';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';

interface Link {
	href: string;
	className: string;

	<T>(a: GatsbyLinkProps<T>): JSX.Element;
}

export const Link: FC<Link> = ({ children, href, className }) => {
	if (href.startsWith('/')) {
		return <GatsbyLink to={href}>{children}</GatsbyLink>;
	}

	const onPage = href.startsWith('#');

	return (
		<a className={className} href={href} target={onPage ? '' : '_blank'} rel={onPage ? '' : 'noopener noreferrer'}>
			{children}
		</a>
	);
};
