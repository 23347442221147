import React from 'react';
import styled from 'styled-components';
import { screenSize } from '../../styles/ScreenSize';
import { MapMeta } from '../../types/maps';

//<editor-fold desc="Styled Components">
const Wrapper = styled.div`
	top: 24px;
	left: 26px;
	right: 75px;
	position: absolute;
	margin-right: 8px;

	${screenSize.sm} {
		top: 15px;
		left: 15px;
		right: 75px;
	}
`;

const Title = styled.div`
	font-weight: bold;
	font-size: 24px;
	color: var(--active-color);

	${screenSize.sm} {
		font-size: 18px;
		line-height: 1.3;
	}
`;

const Year = styled.div`
	font-size: 18px;
	line-height: 30px;
	color: var(--active-color);
`;
//</editor-fold>

interface MapTitle {
	meta?: MapMeta | null;
}

export const MapTitle: React.FC<MapTitle> = ({ meta }) => {
	return (
		<Wrapper>
			<Title id={meta?.name}>{meta ? meta.title : 'Kaart'}</Title>
			{meta?.year && <Year>{meta.year}</Year>}
		</Wrapper>
	);
};
