import React, { ReactNode } from 'react';
import { Source } from 'react-map-gl';
import { MapSource } from '../../types/maps';

interface SourcesLayers {
	mapId: string;
	sources: MapSource[];
	layers: Record<string, ReactNode>;
	promoteId?: (sourceName: string) => string | null;
}

export const SourcesLayers: React.FC<SourcesLayers> = ({ sources, mapId, layers, promoteId }) => {
	return (
		<>
			{sources?.map((source) => (
				<Source
					key={`${source.name}-source-${mapId}`}
					id={source.name}
					type={'geojson'}
					data={source.publicURL}
					promoteId={promoteId?.(source.name)} // @types have this prop missing
					generateId={true}
				>
					{layers[source.name]}
				</Source>
			))}
		</>
	);
};
