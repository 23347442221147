import React, { ReactNode, useCallback, useLayoutEffect, useMemo } from 'react';
import { HTMLOverlay, Layer } from 'react-map-gl';
import { activeExpr, hoverExpr } from './constants';
import { useMapContext } from '../MapContext';
import { SourcesLayers } from '../SourcesLayers';
import styled, { css } from 'styled-components';
import InfoSvg from '../../../images/info_ikoon_medium.svg';

const InfoIcon = styled(InfoSvg)`
	height: 20px;
	fill: #0072ce;
	margin-left: 6px;
`;

interface POILabel {
	isActive?: boolean;
}

const POILabel = styled.button<POILabel>`
	position: absolute;
	background: #ffffff;
	box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
	border: 0;
	border-radius: 2px;
	padding: 4px 7px;
	color: #0072ce;
	transform: translate(-50%, -50%);
	cursor: pointer;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	display: flex;
	align-items: center;

	&&&:focus {
		background: #0072ce;
		color: #ffffff;
		outline: none;

		svg {
			fill: #ffffff;
		}
	}

	${(p) =>
		p.isActive &&
		css`
			&&&& {
				background: #f25c66;
				color: #ffffff;
			}

			svg {
				fill: #ffffff;
			}
		`}
`;

export const S00_linnaosade_areng: React.FC = () => {
	const {
		activeLayers,
		mapData: { sources, meta },
		mapId,
		setSidebarItem,
		sidebarItem,
		mapRef,
	} = useMapContext();

	useLayoutEffect(() => {
		const map = mapRef.current?.getMap();
		if (!map || !map.isStyleLoaded()) return;

		if (!sidebarItem) return;

		map.setFeatureState(
			{
				id: sidebarItem || undefined,
				source: 'S00-linnaosade-areng-linnaosad',
			},
			{ highlighted: true }
		);

		return () => {
			map.setFeatureState(
				{
					id: sidebarItem || undefined,
					source: 'S00-linnaosade-areng-linnaosad',
				},
				{ highlighted: false }
			);
		};
	}, [sidebarItem, mapRef]);

	const redraw = useCallback(
		({ project }) => {
			return meta?.points?.map((poi) => {
				const [cx, cy] = project([poi.lon, poi.lat]);
				return (
					<POILabel
						key={poi.slug}
						style={{ left: cx, top: cy }}
						onClick={() => setSidebarItem(poi.slug)}
						isActive={sidebarItem === poi.slug}
						tabIndex={0}
					>
						{poi.title}
						<InfoIcon />
					</POILabel>
				);
			});
		},
		[meta, sidebarItem]
	);

	const layers: Record<string, ReactNode> = useMemo(
		() => ({
			'S00-linnaosade-areng-linnaosad': (
				<Layer
					key={'S00-linnaosade-areng-linnaosad'}
					id={'S00-linnaosade-areng-linnaosad'}
					type={'fill'}
					source={'S00-linnaosade-areng-linnaosad'}
					paint={{
						'fill-color': 'rgba(252, 214, 217, 0.7)',
						'fill-outline-color': 'rgba(242, 92, 102, 0.6)',
						'fill-opacity': ['case', activeExpr, 1, 0],
					}}
				/>
			),
			'S00-linnaosade-areng-linnapiir': (
				<Layer
					key={'S00-linnaosade-areng-linnapiir'}
					id={'S00-linnaosade-areng-linnapiir'}
					type={'line'}
					source={'S00-linnaosade-areng-linnapiir'}
					layout={{
						'line-cap': 'round',
					}}
					paint={{
						'line-color': '#f79da3',
						'line-opacity': 1,
						'line-dasharray': [2, 2],
						'line-width': {
							base: 2,
							stops: [
								[10, 2],
								[12, 2],
								[16, 16],
							],
						},
					}}
				/>
			),
			'S00-linnaosade-areng-rohekoridorid-promenaadid': (
				<Layer
					key={'S00-linnaosade-areng-rohekoridorid-promenaadid'}
					id={'S00-linnaosade-areng-rohekoridorid-promenaadid'}
					type={'line'}
					source={'S00-linnaosade-areng-rohekoridorid-promenaadid'}
					layout={{
						'line-cap': 'round',
					}}
					paint={{
						'line-color': ['case', hoverExpr, '#F79DA3', '#00963a'],
						'line-opacity': activeLayers.includes('rohekoridorid-promenaadid') ? 1 : 0,
						'line-width': {
							base: 2,
							stops: [
								[10, 2],
								[12, 2],
								[16, 16],
							],
						},
					}}
				/>
			),
			'S00-linnaosade-areng-arendusala-ulelinnaline': (
				<Layer
					key={'S00-linnaosade-areng-arendusala-ulelinnaline'}
					id={'S00-linnaosade-areng-arendusala-ulelinnaline'}
					type={'circle'}
					source={'S00-linnaosade-areng-arendusala-ulelinnaline'}
					paint={{
						'circle-radius': {
							base: 2,
							stops: [
								[0, 0],
								[20, 12590],
							],
						},
						'circle-color': ['case', hoverExpr, 'rgba(239, 51, 64, 0.7)', 'rgba(0, 114, 206, 0.5)'],
						'circle-stroke-color': ['case', hoverExpr, '#EF3340', '#0072CE'],
						'circle-stroke-width': 1.8,
						'circle-opacity': activeLayers.includes('arendusala-ulelinnaline') ? 1 : 0,
						'circle-stroke-opacity': activeLayers.includes('arendusala-ulelinnaline') ? 1 : 0,
					}}
				/>
			),
			'S00-linnaosade-areng-arendusala-piirkondlik': (
				<Layer
					key={'S00-linnaosade-areng-arendusala-piirkondlik'}
					id={'S00-linnaosade-areng-arendusala-piirkondlik'}
					type={'circle'}
					source={'S00-linnaosade-areng-arendusala-piirkondlik'}
					paint={{
						'circle-radius': {
							base: 2,
							stops: [
								[0, 0],
								[20, 6295],
							],
						},
						'circle-color': ['case', hoverExpr, 'rgba(239, 51, 64, 0.7)', 'rgba(0, 114, 206, 0.5)'],
						'circle-stroke-color': ['case', hoverExpr, '#EF3340', '#0072CE'],
						'circle-stroke-width': 1.8,
						'circle-opacity': activeLayers.includes('arendusala-piirkondlik') ? 1 : 0,
						'circle-stroke-opacity': activeLayers.includes('arendusala-piirkondlik') ? 1 : 0,
					}}
				/>
			),
			'S00-linnaosade-areng-trammivork': (
				<Layer
					key={'S00-linnaosade-areng-trammivork'}
					id={'S00-linnaosade-areng-trammivork'}
					type={'line'}
					source={'S00-linnaosade-areng-trammivork'}
					layout={{
						'line-cap': 'round',
					}}
					paint={{
						'line-color': ['case', hoverExpr, '#F79DA3', '#B99E8A'],
						'line-opacity': activeLayers.includes('trammivork') ? 1 : 0,
						'line-width': {
							base: 2,
							stops: [
								[10, 2],
								[12, 2],
								[16, 16],
							],
						},
					}}
				/>
			),
			'S00-linnaosade-areng-roobastranspordi-vajadus': (
				<Layer
					key={'S00-linnaosade-areng-roobastranspordi-vajadus'}
					id={'S00-linnaosade-areng-roobastranspordi-vajadus'}
					type={'line'}
					source={'S00-linnaosade-areng-roobastranspordi-vajadus'}
					layout={{
						'line-cap': 'round',
					}}
					paint={{
						'line-color': ['case', hoverExpr, '#F79DA3', '#666666'],
						'line-opacity': activeLayers.includes('roobastranspordi-vajadus') ? 1 : 0,
						'line-width': {
							base: 2,
							stops: [
								[10, 2],
								[12, 2],
								[16, 16],
							],
						},
					}}
				/>
			),
			'S00-linnaosade-areng-kiire-uhistranspordi-vajadus': (
				<Layer
					key={'S00-linnaosade-areng-kiire-uhistranspordi-vajadus'}
					id={'S00-linnaosade-areng-kiire-uhistranspordi-vajadus'}
					type={'line'}
					source={'S00-linnaosade-areng-kiire-uhistranspordi-vajadus'}
					layout={{
						'line-cap': 'round',
					}}
					paint={{
						'line-color': ['case', hoverExpr, '#F79DA3', '#66AAE2'],
						'line-opacity': activeLayers.includes('kiire-uhistranspordi-vajadus') ? 1 : 0,
						'line-width': {
							base: 2,
							stops: [
								[10, 2],
								[12, 2],
								[16, 16],
							],
						},
					}}
				/>
			),
			'S00-linnaosade-areng-peatanav': (
				<Layer
					key={'S00-linnaosade-areng-peatanav'}
					id={'S00-linnaosade-areng-peatanav'}
					type={'line'}
					source={'S00-linnaosade-areng-peatanav'}
					layout={{
						'line-cap': 'round',
					}}
					paint={{
						'line-color': ['case', hoverExpr, '#F79DA3', '#F6DA65'],
						'line-opacity': activeLayers.includes('peatanav') ? 1 : 0,
						'line-width': {
							base: 2,
							stops: [
								[10, 2],
								[12, 2],
								[16, 16],
							],
						},
					}}
				/>
			),
			'S00-linnaosade-areng-arendusobjekt': (
				<Layer
					key={'S00-linnaosade-areng-arendusobjekt'}
					id={'S00-linnaosade-areng-arendusobjekt'}
					type={'symbol'}
					source={'S00-linnaosade-areng-arendusobjekt'}
					layout={{
						'icon-allow-overlap': true,
						'icon-image': 'pin-01-blue',
						'icon-anchor': 'bottom',
						'icon-size': 0.8,
					}}
					paint={{
						'icon-opacity': activeLayers.includes('arendusobjekt') ? 1 : 0,
					}}
				/>
			),
		}),
		[activeLayers]
	);

	const promoteId: Record<string, string | null> = useMemo(() => ({ 'S00-linnaosade-areng-linnaosad': 'slug' }), []);

	if (!sources) return null;

	return (
		<>
			<SourcesLayers
				mapId={mapId}
				layers={layers}
				sources={sources}
				promoteId={(sourceName) => promoteId[sourceName]}
			/>
			<HTMLOverlay redraw={redraw} />
		</>
	);
};
