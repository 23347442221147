import { Expression } from 'mapbox-gl';
import { scaleLinear } from 'd3-scale';
import { HTMLOverlay, HTMLRedrawOptions, Layer } from 'react-map-gl';
import * as React from 'react';
import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useMapContext } from '../MapContext';
import { gridSourceLayerName, hoverExpr } from './constants';
import { POILabel } from '../PoiLabel';
import { SourcesLayers } from '../SourcesLayers';

export function S13_ut_uhendused() {
	const {
		mapData: { data, meta, sources },
		activeLayers,
		setActiveLayers,
		mapId,
	} = useMapContext();

	const redraw = useCallback(
		({ project }: HTMLRedrawOptions) => {
			return meta?.points?.map((poi) => {
				const [cx, cy] = project([poi.lon, poi.lat]);
				return (
					<POILabel
						key={poi.slug}
						style={{ left: cx, top: cy }}
						onClick={() => setActiveLayers([poi.slug])}
						isActive={activeLayers.includes(poi.slug)}
					>
						{poi.title}
					</POILabel>
				);
			});
		},
		[activeLayers]
	);

	useLayoutEffect(() => {
		if (activeLayers.length < 1) setActiveLayers(['hobujaama']);
	}, [activeLayers, setActiveLayers]);

	const expr = useMemo(() => {
		let expr: Expression = ['match', ['get', 'GRD_INSPIR']];

		if (!data) return 'rgba(0,0,0,0)';

		const colorScale = scaleLinear<string>()
			.domain([1, 11, 21, 31, 41, 51, 61, 71, 81])
			.range(['#009639', '#3DA53A', '#7AB33C', '#B6C23D', '#F3D03E', '#F2A93E', '#F1823F', '#F05A3F', '#EF3340'])
			.clamp(true);

		for (let [key, value] of Object.entries(data)) {
			const val = value[activeLayers[0]];

			if (val !== -1) {
				expr.push(key, colorScale(val || null));
			}
		}

		expr.push('rgba(0,0,0,0)');
		return expr;
	}, [data, activeLayers]);

	if (!data || !sources) return null;

	const layers = {
		's13-ut_uhendused-sihtkohad': (
			<Layer
				key={'s13-ut_uhendused-sihtkohad'}
				id={'s13-ut_uhendused-sihtkohad'}
				type={'symbol'}
				source={'s13-ut_uhendused-sihtkohad'}
				layout={{ 'icon-image': 'pin-01', 'icon-anchor': 'bottom', 'icon-size': 0.75 }}
				paint={{}}
			/>
		),
	};

	return (
		<>
			<HTMLOverlay redraw={redraw} />
			<Layer
				key={`${mapId}-grid`}
				id={'grid'}
				type={'fill'}
				source={'composite'}
				source-layer={gridSourceLayerName}
				paint={{
					'fill-color': ['case', hoverExpr, '#F79DA3', expr],
					'fill-outline-color': ['case', hoverExpr, '#EF3340', 'rgba(0,0,0,0)'],
					'fill-opacity': 0.7,
				}}
			/>
			<SourcesLayers mapId={mapId} sources={sources} layers={layers} />
		</>
	);
}
