import { MapRef } from 'react-map-gl';
import { RefObject, useEffect, useState } from 'react';
import { HoverFeature } from '../types/maps';
import { usePrevious } from './usePrevious';

export function useMapHover(mapRef: RefObject<MapRef> | null) {
	const [currentHoverFeatures, setHoverFeatures] = useState<HoverFeature[]>([]);
	const previousFeatures = usePrevious<HoverFeature[]>(currentHoverFeatures);

	function clearPreviousFeatures() {
		previousFeatures?.forEach((feature) => setFeatureHoverState(feature, false));
	}

	function applyCurrentHover() {
		currentHoverFeatures?.forEach((feature) => setFeatureHoverState(feature, true));
	}

	function setFeatureHoverState(feature: HoverFeature, hover: boolean) {
		const map = mapRef?.current?.getMap();

		if (!map || feature.id === null || feature.id === undefined || !feature.source) return;
		map.setFeatureState(
			{ id: feature.id, source: feature.source, sourceLayer: feature.sourceLayer },
			{ hover, id: feature.properties.id }
		);
	}

	useEffect(() => {
		clearPreviousFeatures();
		applyCurrentHover();
	}, [currentHoverFeatures, mapRef]);

	return { setHoverFeatures };
}
