import React, { useMemo } from 'react';
import { scalePow } from 'd3-scale';
import { Layer } from 'react-map-gl';
import { hoverExpr } from './constants';
import { SourcesLayers } from '../SourcesLayers';
import { useMapContext } from '../MapContext';

export const S31_asumi_sissetulek: React.FC = () => {
	const {
		mapId,
		mapData: { sources },
	} = useMapContext();

	const expr = useMemo(() => {
		let expr = [];
		const colorScale = scalePow<string>().domain([0, 2000]).exponent(2.4).range(['#DFECF6', '#0072CE']).clamp(true);

		for (let i = 0; i <= 2000; i += 250) {
			expr.push(i, colorScale(i));
		}

		return expr;
	}, []);

	const layers = {
		'S31-asumi-sissetulek': (
			<Layer
				key={'S31-asumi-sissetulek'}
				id={'S31-asumi-sissetulek'}
				type={'fill'}
				source={'S31-asumi-sissetulek'}
				paint={{
					'fill-color': [
						'case',
						hoverExpr,
						'#F79DA3',
						['case', ['==', ['get', 'val'], -1], '#999999', ['interpolate', ['linear'], ['get', 'val'], ...expr]],
					],
					'fill-outline-color': ['case', hoverExpr, '#EF3340', 'rgba(0,0,0,0)'],
					'fill-opacity': 0.7,
				}}
			/>
		),
	};

	if (!sources) return null;

	return <SourcesLayers mapId={mapId} sources={sources} layers={layers} />;
};
