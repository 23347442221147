import React from 'react';
import { Layer } from 'react-map-gl';
import { hoverExpr } from './constants';
import { SourcesLayers } from '../SourcesLayers';
import { useMapContext } from '../MapContext';

export const S28_miljoo: React.FC = () => {
	const {
		mapId,
		mapData: { sources },
	} = useMapContext();

	const layers = {
		'S28-miljoo': (
			<Layer
				key={'S28-miljoo'}
				id={'S28-miljoo'}
				type={'fill'}
				source={'S28-miljoo'}
				paint={{
					'fill-color': ['case', hoverExpr, '#F79DA3', '#F3D03E'],
					'fill-outline-color': ['case', hoverExpr, '#EF3340', 'rgba(0,0,0,0)'],
					'fill-opacity': 0.6,
				}}
			/>
		),
	};

	if (!sources) return null;

	return <SourcesLayers mapId={mapId} sources={sources} layers={layers} />;
};
